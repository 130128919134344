import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import {withStoryGrid} from 'components/storygrid'
import {Card} from '../card'
import {Content} from 'components/crops' 

export const CropsGrid = (props) => {
  const {crops} = useStaticQuery(graphql`
    query allCrops{
      crops: allWordpressWpCrops{
        nodes{
          title
          path
          content
          featured_media{
            localFile{
              childImageSharp{
                fluid( maxHeight:292 ){
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
gallery {
      localFile{
        ...GalleryThumbnail
      }
    }
    documents {
      title
      link {
        target
        url
      }
      content:description
      isDownloadable
      thumbnail {
        localFile {
          ...ContentCardImages
        }
      }
    }
        }
      }
    }`)
  const Grid = withStoryGrid(
    Card,
    Content,
    crops.nodes.map(n=>{
      if(n.featured_media && n.featured_media.localFile){
        return {
          ...n,
          image: n.featured_media.localFile.childImageSharp.fluid
        }
      }
    }),
  )

  return (<Grid />)

}
