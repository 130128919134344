import React from 'react';
import {graphql} from 'gatsby';
import Layout from 'components/layout'
import {CropsGrid} from 'components/crops/parts/grid'
import {TitleBanner} from 'components/banners/titleBanner'
import {BgPattern} from 'components/backgrounds'
import BgStyle from 'components/backgrounds/patterns.module.css'
import Style from './archivecrops.module.css'
const ArchiveCropsTemplate = ({data, ...props}) => {
return(
  <Layout
    articleId={`${data.page.type}-${data.page.wordpress_id}`}
    articleClassName={`${data.page.classes}`}
    context = {props.pageContext}
    location = {props.location}
    ancestor = {data.page.ancestors}
  >
    <div className="container px-5 mx-auto">
      <TitleBanner title={data.page.title} />
      <div className="entry container px-5 relative " >
        
        <div className="entry-content" dangerouslySetInnerHTML={{__html:data.page.content}}/>
        
        <div style={{maxWidth: 1240}} className="relative mt-20 mx-auto">
          <BgPattern className={`${BgStyle.pattern3} ${Style.bgTop}`}/>
          <CropsGrid/>
        </div>
        <BgPattern className={`${BgStyle.pattern1} ${Style.bgBottom}`}/>
      </div>
    </div>
  </Layout>
)
}

export default ArchiveCropsTemplate

export const SingleCropsQuery = graphql `
query CropsArchiveById($id: String!){
  page: wordpressPage(id:{eq:$id}){
    title
    content
    path
    type
    date
    wordpress_id
    classes
  }

  crops: allWordpressWpCrops{
   nodes{

    title
    content
    path
    type
    date
    wordpress_id
    featured_media {
     localFile {
       ...PageBannerImages
     }
    }
    classes
    gallery {
      localFile{
        ...GalleryThumbnail
      }
    }
    documents {
      title
      link {
        target
        url
      }
      content:description
      isDownloadable
      thumbnail {
        localFile {
          ...ContentCardImages
        }
      }
    }
  }
 }
}
`

